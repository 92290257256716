import { Table } from "@/code/models/tables/Table";
import { BaseService } from "@/code/services/base/baseService";
import axios2 from '@/axios2';
import { baseApiUrl } from "@/global";

export class TablesService extends BaseService<Table> {

  constructor() {
    super(Table);
  }

  getAllByAccount(idAccount: number) : Promise<Array<Table>> {
    const that = this;
    return new Promise((resolve, reject) => {
      axios2.get(`tables?all=1&table_id=${idAccount}&table_type=accounts`)
      .then(
        (response: any) => {
          const result = new Array<Table>();
          if (response.data && response.data.data) {
            response.data.data.forEach((el: any) => {
              result.push(new Table(parseInt(el.id), el));
            });
          }
          resolve(result);
        }
      ),
      (error: any) => {
        reject(error);
      }
    })
  }

  // Retorna as mesma visão de tabelas que são exibidas no APP
  getAccountApp(idAccount: number) : Promise<Array<Table>> {
    const that = this;
    return new Promise((resolve, reject) => {
      axios2.get(`tables?account_id=${idAccount}`)
      .then(
        (response: any) => {
          const result = new Array<Table>();
          if (response.data && response.data.data) {
            response.data.data.forEach((el: any) => {
              result.push(new Table(parseInt(el.id), el));
            });
          }
          resolve(result);
        }
      ),
      (error: any) => {
        reject(error);
      }
    })
  }

  saveTable(description: string, type: number, files,
      usarComoTabelaGeral: Boolean, id: number) : Promise<Table> {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      const scope = usarComoTabelaGeral ? 'accounts' : 'users';

      for (var i = 0; i <= files.length - 1; i++ ) {
        data.append('tables', files[i]);
      }
      data.append('description', description);
      data.append('scope', scope);
      data.append('id', id.toString());
      data.append('product_type', type.toString());

      delete axios2.defaults.headers.common['X-Requested-With'];
      axios2.defaults.headers.common['Content-Type'] = 'multipart/form-data';

      axios2.post(`${baseApiUrl}tables`, data)
      .then(
        (response) => {
          if (response && response.status >= 200 && response.status < 300) {
            const table = new Table(response.data.data[0].id, response.data.data[0]);
            resolve(table);
          } else {
            reject();
          }
        }
      )
    });
  }

  copyTable(idTable: number, idAccount: number) : Promise<Boolean> {
    const data = {
      table: idTable,
      account: idAccount
    }
    return new Promise((resolve, reject) => {
      axios2.post('tables/copy', data)
      .then(
        (response: any) => {
          if (response.status >= 200 && response.status < 300) {
            resolve(true);
          }
        },
        (error) => {
          reject();
        }
      )
    });
  }

  getUrl() : String {
    return 'tables';
  }
}

<template>
  <v-container
    id="accounts"
    fluid
    tag="section"
    class="box-price-tables"
  >
    <div class="text-h3 font-weight-bold">
      Gerenciar as tabelas de preço
    </div>
    <v-row no-gutters class="ml-4 mt-4" v-if="!actionTableSelected">
      <v-col cols="12" md="6" class="mt-3">
        <v-row>
          <div class="text-h4 font-weight-bold">
            Tabelas de preço utilizadas
          </div>
        </v-row>
        <v-row cols="12" md="6" class="mt-3">
          <div class="text-h5 font-weight-normal">
            Remova as tabelas de preço que não gostaria de utilizar nessa loja
          </div>
        </v-row>
      </v-col>
      <v-col cols="12" md="2" offset="4">
        <v-row no-gutters class="pr-3">
          <v-btn block
              class="ml-3 font-size-12"
              color="purple"
              @click="actionAddTable = true"
            >
              <i class="fas fa-plus font-size-18 mr-3"></i>
              Adicionar tabela
            </v-btn>
        </v-row>
        <v-row no-gutters class="pr-3">
          <v-btn outlined block
              class="ml-3 font-size-12 mt-1"
              color="purple"
              @click="actionSelectTable = true"
            >
              Selecionar tabela existente
            </v-btn>
        </v-row>
        <v-row no-gutters class="pr-3">
          <v-btn outlined block
              class="ml-3 font-size-12 mt-1"
              color="error"
              @click="deleteTables"
            >
              Excluir tabelas selecionadas
            </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters v-if="!actionTableSelected" class="mt-8">
      <v-col cols="12" md="12">
        <v-data-table
          :headers="headersTable"
          :items="tablesList"
          :items-per-page="10"
          :search="filterTextTable"
          class="elevation-1"
          item-key="id" show-select v-model="tablesToRemove"
          :loading="loadingTables"
          loading-text="Carregando tabelas de preço..."
          @toggle-select-all="selectAllToggle"
        >
          <template v-slot:top>
            <v-toolbar
              flat
            >
              <v-col cols="12" sm="6">
                <v-toolbar-title>Tabelas de preço</v-toolbar-title>
              </v-col>
              <v-col cols="12" sm="3" offset-sm="3">
                <v-text-field
                  class="mt-8"
                  label="Filtrar"
                  v-model="filterTextTable"
                  solo
                  append-icon="mdi-magnify"
                ></v-text-field>
              </v-col>
            </v-toolbar>
          </template>
          <template v-slot:item.data-table-select="{ item, isSelected, select }">
            <v-simple-checkbox
                :ripple="false"
                :value="isSelected"
                :readonly="!item.is_owner"
                :disabled="!item.is_owner"
                @input="select($event)"
            ></v-simple-checkbox>
          </template>
          <template v-slot:item.labels="{ item }">
            {{ getFormattedLabels(item.labels) }}
          </template>
          <template v-slot:item.modified="{ item }">
            {{ getFormattedDate(item.modified) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row no-gutters v-if="actionAddTable">
      <v-col cols="12" md="12">
        <ValidationObserver
          ref="formAddTable"
          class="w-full"
          v-slot="{ pristine }"
          :key="formAddTable">
          <form key="formAddTable">
            <v-row
              class="mx-auto"
              justify="space-around"
              style="max-width: 1000px;"
            >
              <v-col
                cols="8"
                class="text-center"
              >
                <template>
                  <ValidationProvider
                    name="file"
                    vid="file"
                    v-slot="{ errors }"
                    :rules="{ required: filesCount }"
                  >
                    <input
                      ref="file"
                      type="file"
                      class="d-none"
                    >
                    <v-file-input
                      v-model="files"
                      accept=".json, .csv,"
                      label="Carregar Tabela JSON"
                      prepend-icon="mdi-cloud-upload"
                      :placeholder="fileLoaderPlaceholder"
                      outlined
                      :show-size="1000"
                      counter
                      :loading="fileIsLoading"
                      :disabled="fileLoaderIsDisabled"
                      class="display-3"
                      multiple
                      :error-messages="errors"
                      @change="onChange"
                    >
                      <template v-slot:selection="{ index, text }">
                        <v-chip
                          v-if="index < 2"
                          color="deep-purple accent-4"
                          dark
                          label
                          class="display-3"
                        >
                          {{ text }}
                        </v-chip>
                        <span
                          v-else-if="index === 2"
                          class="overline grey--text text--darken-3 mx-2"
                        >
                          +{{ files.length - 2 }} Arquivo(s)
                        </span>
                      </template>
                    </v-file-input>
                  </ValidationProvider>
                </template>
                <div class="font-weight-bold grey--text">
                  Carregar Arquivo JSON
                </div>
              </v-col>
            </v-row>
            <v-row class="ml-2">
              <v-col
                cols="12"
                md="6"
                sm="6"
              >
                <v-subheader class="display-1">
                  Descrição
                </v-subheader>
                <ValidationProvider
                    name="tableDescription"
                    vid="tableDescription">
                  <v-text-field
                    v-model="tableDescription"
                    label="Adicione uma descição para tabela"
                  />
                </ValidationProvider>
              </v-col>
              <v-col
                cols="12"
                md="6"
                sm="6"
              >
                <v-subheader class="display-1">
                  Tipo de Produto
                </v-subheader>
                <ValidationProvider
                    name="tableType"
                    vid="tableType">
                  <v-radio-group
                    v-model="tableType"
                    column
                    class="ml-4 mt-0 radio-inline"
                  >
                    <v-radio
                      label="Multifocal"
                      :value="0"
                    />
                    <v-radio
                      label="Simples"
                      :value="1"
                    />
                    <v-radio
                      label="Ocupacional"
                      :value="2"
                    />
                    <v-radio
                      label="Bifocal"
                      :value="3"
                    />
                  </v-radio-group>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="2" offset="8" class="pr-2">
                <v-btn outlined color="warning" @click="cancelTable" block>
                  <i class="fas fa-ban font-size-18 mr-3"></i>
                  Cancelar
                </v-btn>
              </v-col>
              <v-col cols="12" md="2" class="pl-2">
                <v-btn color="success" @click="saveTable" block>
                  <i class="fas fa-save font-size-18 mr-3"></i>
                  Salvar
                </v-btn>
              </v-col>
            </v-row>
          </form>
        </ValidationObserver>
      </v-col>
    </v-row>
    <template v-if="actionSelectTable">
      <v-row no-gutters class="mt-8">
        <v-col cols="12" sm="12" class="pl-3">
          <div class="text-h4 font-weight-normal">Selecione a loja para carregar as tabelas de preço</div>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" sm="4" class="text-center px-3">
          <v-select
                color="secondary"
                item-color="secondary"
                label="Escolha a loja para revisar"
                :items="filteredAccounts"
                v-model="selectedAccount"
                :item-text="item => `${item.id} - ${item.account}`"
                return-object
              >
              <template v-slot:prepend-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-text-field label="Filtrar" v-model="accountsFilter" />
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
        </v-col>
        <v-col cols="12" sm="2" class="text-center px-3">
          <v-btn color="primary" @click="loadPriceTablesFromAccount">
            <i class="mdi mdi-magnify-plus-outline font-size-24 mr-4 px-2" />
            Carregar tabelas de preço
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters class="ml-4 mt-4">
        <v-col cols="12" md="6" class="mt-3">
          <v-row>
            <div class="text-h3 font-weight-bold">
              Selecione as tabelas para adicionar à loja
            </div>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="12">
          <v-data-table
            :headers="headersTableToSelect"
            :items="tablesToSelectList"
            :items-per-page="10"
            :search="filterTextTablesToSelect"
            class="elevation-1"
            item-key="id"
            show-select
            v-model="tablesToAdd"
            :loading="loadingTablesToSelect"
            loading-text="Carregando tabelas de preço..."
          >
            <template v-slot:top>
              <v-toolbar
                flat
              >
                <v-col cols="12" sm="3" offset-sm="9">
                  <v-text-field
                    class="mt-8"
                    label="Filtrar"
                    v-model="filterTextTablesToSelect"
                    solo
                    append-icon="mdi-magnify"
                  ></v-text-field>
                </v-col>
              </v-toolbar>
            </template>
            <template v-slot:item.labels="{ item }">
              {{ getFormattedLabels(item.labels) }}
            </template>
            <template v-slot:item.modified="{ item }">
              {{ getFormattedDate(item.modified) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-5">
        <v-col cols="12" md="2" offset="8" class="pr-2">
          <v-btn outlined color="warning" @click="cancelSelectTables" block>
            <i class="fas fa-ban font-size-18 mr-3"></i>
            Cancelar
          </v-btn>
        </v-col>
        <v-col cols="12" md="2" class="pl-2">
          <v-btn color="success" @click="addTables" block>
            <i class="fas fa-save font-size-18 mr-3"></i>
            Salvar
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <v-row no-gutters v-if="!actionTableSelected" class="mt-5">
      <v-col cols="12" md="2">
        <v-btn @click="previousTab" block>
          Anterior
        </v-btn>
      </v-col>
      <v-col cols="12" md="2" offset="8">
        <v-btn @click.native="nextTab" block class="wizard-footer-right finish-button" color="primary">
            Próximo
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="tablesFailed && tablesFailed.length > 0"
      max-width="400px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="headline">Ocorreu um erro ao excluir as seguintes tabelas de preço</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <ul>
              <li v-for="table in tablesFailed">{{ table.id }} - {{ table.description }}</li>
            </ul>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="closeFailedTables"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<style>
.box-price-tables .v-simple-checkbox {
  background: #a3f19d
}

.box-price-tables .v-simple-checkbox--disabled {
  background: #f89090 !important;
}
</style>
<script>
import { TablesService } from '@/code/services/tables/TablesService';
import { Confirm, Loading, Toast } from '@/code/util/swalUtil';
import Swal from 'sweetalert2';
import moment from 'moment';
import { AccountService } from '@/code/services/user/accountService';
import { EventBus } from '@/code/util/eventBus';

export default {

  data() {
    return {
      idAccount: 0,
      tablesList: [],
      tablesFailed: [],
      filterTextTable: "",
      loadingTables: false,
      tablesToSelectList: [],
      filterTextTablesToSelect: "",
      loadingTablesToSelect: false,
      tablesToAdd: [],
      actionAddTable: false,
      actionSelectTable: false,
      tableDescription: '',
      tableType: -1,
      files: [],
      tablesToRemove: [],
      actionAddTable: false,
      actionSelectTable: false,
      accountsList: [],
      selectedAccount: {},
      accountsFilter: '',
      headersTable: [
        {
          text: 'Id',
          value: 'id',
          filterable: false
        },
        {
          text: 'Descrição',
          value: 'description',
          filterable: true
        },
        {
          text: 'Tipo de produto',
          value: 'product_type_label',
          filterable: true
        },
        {
          text: 'Labels',
          value: 'labels',
          filterable: true
        },
        {
          text: 'Data modificação',
          value: 'modified',
          filterable: false
        },
        { text: 'Ações', value: 'actions', sortable: false }
      ],
      headersTableToSelect: [
        {
          text: 'Id',
          value: 'id',
          filterable: false
        },
        {
          text: 'Descrição',
          value: 'description',
          filterable: true
        },
        {
          text: 'Tipo de produto',
          value: 'product_type_label',
          filterable: true
        },
        {
          text: 'Labels',
          value: 'labels',
          filterable: true
        },
        {
          text: 'Data modificação',
          value: 'modified',
          filterable: false
        }
      ],
    }
  },

  mounted() {
    const that = this;
    const funcError = () => {
      Swal.fire({
        title: 'Parâmetro inválido',
        text: 'A loja informada é invalida!',
        icon: 'error'
      })
      .then(
        (result) => {
          that.$router.replace({ name: 'assistentAction' });
        }
      );
    }
    if (this.$route.params.id) {
      try {
        this.idAccount = parseInt(this.$route.params.id);
        this.loadTables();
      } catch (error) {
        funcError();
      }
    } else {
      funcError();
    }
  },

  methods: {
    loadTables: async function() {
      if (this.idAccount && this.idAccount > 0) {
        this.loadingTables = true;
        this.tablesList = [];
        const tableService = new TablesService();
        this.tablesList = await tableService.getAccountApp(this.idAccount);
        this.loadingTables = false;
      } else {
        this.tablesList = [];
      }
    },
    selectAllToggle(props) {
      this.tablesToRemove = [];
      const self = this;
      props.items.forEach(item => {
        if(item.is_owner) {
          self.tablesToRemove.push(item);
        }
      });
    },
    cancelTable: async function() {
      const dirty = await this.$refs.formAddTable.flags.dirty;
      if (dirty) {
        Confirm.fire('Os dados da tabela de preço serão perdidos. Deseja continuar?')
        .then((result) => {
          if (result.isConfirmed) {
            this.tableDescription = '',
            this.tableType = -1,
            this.files = [],
            this.actionAddTable = false;
          }
        })
      } else {
        this.actionAddTable = false;
      }
    },
    saveTable: async function() {
      const service = new TablesService();
      const table = await service.saveTable(this.tableDescription, this.tableType,
        this.files, true, this.idAccount);
      if (table && table.id > 0) {
        this.tableDescription = '',
        this.tableType = -1,
        this.files = [],
        this.actionAddTable = false;
        this.tablesList.push(table);
      }
    },
    deleteTables: async function() {
      if (this.tablesToRemove && this.tablesToRemove.length > 0) {
        const that = this;
        const failed = [];
        Confirm.fire('Deseja realmente excluir as tabelas selecionadas?')
        .then(
          (result) => {
            if (result.isConfirmed) {
              Loading.fire('Excluindo tabelas de preço...');
              const service = new TablesService();
              let cont = 0;
              that.tablesToRemove.forEach(async (table) => {
                const sucesso = await service.delete(table.id);
                cont++;
                if (sucesso) {
                  that.tablesList.splice(that.tablesList.indexOf(table), 1);
                } else {
                  this.tablesFailed.push(table);
                }
                if (cont === that.tablesToRemove.length) {
                  Swal.close();
                  that.tablesToRemove = [];
                  that.loadTables();
                }
              });
            }
          }
        )
      }
    },
    closeFailedTables: function() {
      this.tablesFailed = [];
    },
    cancelSelectTables: async function() {
      const that = this;
      if (this.tablesToAdd && this.tablesToAdd.length > 0) {
        Confirm.fire(`As tabelas selecionadas não serão adicionadas à conta. Deseja cancelar a seleção?`)
        .then(
          (result) => {
            if (result.isConfirmed) {
              that.actionSelectTable = false;
              that.tablesToAdd = [];
              that.selectedAccount = {};
            }
          }
        )
      } else {
        that.actionSelectTable = false;
        that.tablesToAdd = [];
        that.selectedAccount = {};
      }
    },
    addTables: async function() {
      if (!this.tablesToAdd || this.tablesToAdd.length === 0) {
        Swal.fire('Nenhuma tabela selecionada para ser copiada para a loja.');
      } else {
        Loading.fire("Copiando as tabelas...");
        const that = this;
        let errorToAdd = [];
        const service = new TablesService();
        for (let index = 0; index < this.tablesToAdd.length; index++) {
          const tb = this.tablesToAdd[index];
          try {
            const sucesso = await service.copyTable(tb.id, that.idAccount);
            if (!sucesso) {
              errorToAdd.push(tb);
            }
          } catch (error) {
            errorToAdd.push(tb);
          }
        }
        that.tablesToAdd = [];
        that.selectedAccount = {};
        that.tablesToSelectList = [];
        if (errorToAdd.length > 0) {
          let msgError = '';
          errorToAdd.forEach(tbError => {
            if (msgError.length > 0) {
              msgError += '<br />';
            }
            msgError += `<span>${ tbError.id } - ${ tbError.description }</span>`;
          });
          Swal.fire({
            title: 'Ocorreu erro ao copiar as tabelas de preço:',
            html: msgError,
            icon: 'error'
          });
        } else {
          Swal.close();
          that.actionSelectTable = false;
          that.loadTables();
        }
      }
    },
    getFormattedDate: function(date) {
      return date ? moment.utc(date).format('DD/MM/YYYY') : '';
    },
    getFormattedLabels: function(labels) {
      if(!labels){
        return ''
      }

      try {
        return JSON.parse(labels).join(', ');
      }catch (error) {
        console.error(error);
        return '';
      }
    },
    loadAccounts: async function() {
      const service = new AccountService();
      this.accountsList = await service.getAllAccounts(false);
      const that = this;
      const editingAccount = this.accountsList.filter((ac) => ac.id === that.idAccount);
      if (editingAccount && editingAccount.length > 0) {
        editingAccount.forEach(ac => {
          that.accountsList.splice(that.accountsList.indexOf(ac), 1);
        });
      }
    },
    loadPriceTablesFromAccount: async function() {
      if (!this.selectedAccount || !this.selectedAccount.id || this.selectedAccount.id <= 0) {
        Swal.fire("Selecione uma loja para carregar as tabelas de preço.");
      } else {
        this.loadingTablesToSelect = true;
        const tableService = new TablesService();
        this.tablesToSelectList = await tableService.getAllByAccount(this.selectedAccount.id);
        this.loadingTablesToSelect = false;
      }
    },
    previousTab: function() {
      EventBus.$emit('previousAssistentRoute');
    },
    nextTab: function() {
      EventBus.$emit('nextAssistentRoute');
    }
  },

  computed: {
    actionTableSelected() {
      return this.actionAddTable || this.actionSelectTable;
    },
    filteredAccounts: function() {
      if (!this.accountsFilter) {
        return this.accountsList;
      } else {
        return this.accountsList.filter((el) => el.id.toString() === this.accountsFilter || el.account.toLowerCase().includes(this.accountsFilter.toLowerCase()));
      }
    }
  },

  watch: {
    actionSelectTable(newValue) {
      if (newValue === true) {
        this.loadAccounts();
      }
    }
  }

}
</script>

import { Model } from "../Model";

export class Table extends Model {

  constructor(id: number, attributes: any) {
    super(id);
    this.description = attributes.description;
    this.table_type = attributes.table_type;
    this.table_id = attributes.table_id;
    this.product_type = attributes.product_type;
    this.product_type_label = attributes.product_type_label;
    this.is_owner = !!attributes.is_owner;
    this.labels = attributes.labels;
    this.created = attributes.created;
    this.modified = attributes.modified;
  }

  description: String;
  table_type: String;
  table_id: number;
  product_type: number;
  product_type_label: String;
  is_owner: boolean;
  labels: string;
  created: Date;
  modified: Date;
}
